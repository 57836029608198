@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk');

.space-grotesk {
  font-family: 'Space Grotesk', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.partnerships-bg {
  background-image: url('../public/images/landing/partnerships/3.webp');
  background-size: 74.6rem;
  background-position: right -10% bottom 50%;
  background-repeat: no-repeat;
}

@media (max-width: 992px) {
  .partnerships-bg {
    background-position: bottom -40% right 50%;
    background-size: 50rem;
  }
}

button {
  outline: none;
}

.hide-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.gradient-text {
  &-purple {
    background: -webkit-linear-gradient(90deg, #754bff -14.18%, #c09aff 99.6%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

html {
  font-size: 10px;
  line-height: normal;
  background-color: #020204;
}

img,
svg {
  pointer-events: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.landing {
  &-swap-gradient {
    background-image: url('../public/images/landing/openSale/bg-gradient.webp');
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;

    @media all and (max-width: 992px) {
      background-image: url('../public/images/landing/openSale/bg-gradient-mobile.webp');
      background-position: bottom right;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

.previewPage {
  &-polyBg {
    background: linear-gradient(
      0deg,
      rgba(2, 2, 4, 1) 15%,
      rgba(2, 2, 4, 0.85) 20%,
      rgba(2, 2, 4, 0.75) 35%,
      rgba(2, 2, 4, 0) 45%
    );
  }
  &-note {
    border-radius: 3rem;
    background: #9742da;
    box-shadow: 0px 0px 2px 0px rgba(255, 255, 255, 0.5) inset, 0px 0px 15px 0px #c17bf8;
    width: 0.4rem;
    height: 5.4rem;

    @media all and (max-width: 992px) {
      width: 5.4rem;
      height: 0.4rem;
    }
  }
  &-header {
    &-btn {
      box-shadow: 0px 10px 50px 5px rgba(152, 90, 255, 0.35);
    }
  }
}

.poly {
  &-animation-show {
    animation: show-poly 2s ease-in-out;
  }
}

@keyframes show-poly {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.custom-animation {
  &-bounce {
    animation: custom-bounce 10s ease-in-out infinite;
  }
}

@keyframes custom-bounce {
  0%,
  100% {
    transform: translateY(-30%);
  }
  50% {
    transform: translateY(0);
  }
}

.checking {
  display: inline-block;
  clip-path: inset(0 3ch 0 0);
  animation: l 1.5s steps(8) infinite;
}
@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0);
  }
}

.shadowTest {
  mask: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #000 10%, #000 90%, rgba(0, 0, 0, 0) 100%);
}

.register-text-aniamtion {
  animation: check-text 2s infinite;
}
@keyframes check-text {
  0% {
    color: #ffffff;
  }
  20% {
    color: #ffffff80;
  }
  40% {
    color: #ffffffcc;
  }
  70% {
    color: #ffffff33;
  }
  100% {
    color: #ffffff;
  }
}

.loading-animation {
  &__black {
    animation: rotate 1s linear infinite;
    background-image: url('/assets/icons/generalIcons/loading/black.svg');
    background-position: center;
    background-size: cover;
    width: 2.4rem;
    height: 2.4rem;
    @media all and (max-width: 992px) {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
  &__white {
    animation: rotate 1s linear infinite;
    background-image: url('/assets/icons/generalIcons/loading/white.svg');
    background-position: center;
    background-size: cover;
    width: 2.4rem;
    height: 2.4rem;
  }
  &__purple {
    animation: rotate 1s linear infinite;
    background-image: url('/assets/icons/register/terms/loading.svg');
    background-position: center;
    background-size: cover;
    width: 2.4rem;
    height: 2.4rem;
  }
}

.alert-animation {
  background-image: url('/assets/icons/register/terms/warning.svg');
  background-position: center;
  background-size: cover;
  width: 2.4rem;
  height: 2.4rem;
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

.bgProfile {
  background: rgba(255, 255, 255, 0.05);
  @media all and (max-width: 992px) {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%), rgba(0, 0, 0, 0.8);
  }
}

.bgLevelCard {
  background: rgba(255, 255, 255, 0.05);
  @media all and (max-width: 992px) {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%), #020204;
  }
}

.tiersListBg {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.01) 100%), #020204;
  @media all and (max-width: 992px) {
    background: transparent;
  }
}

.partnersships-gradient {
  background: radial-gradient(123.4% 137.12% at 50% 11.74%, #fff 0%, rgba(255, 255, 255, 0.3) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.journeyList-bg {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), #020204;
}

.notification-bg {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%), #020204 !important;
  box-shadow: 0px 14px 24px 0px rgba(2, 2, 4, 0.4) !important;
}

.slider-animation {
  transition: transform 0.5s ease-in-out; /* Плавный переход */
}

.progress-bar {
  transition: width 2s linear;
}

.fade {
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.scroll-animation {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.7s ease-in-out, transform 0.7s ease-in-out;
}

.scroll-animation.animate {
  opacity: 1;
  transform: translateY(0);
}

@mixin gradient-striped($color: #906c31, $angle: -45deg) {
  background-image: linear-gradient(
    $angle,
    $color 25%,
    transparent 25%,
    transparent 50%,
    $color 50%,
    $color 75%,
    transparent 75%,
    transparent
  );
}

@keyframes progress-bar-stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 80px 0;
  }
}

@mixin animation($animation) {
  -webkit-animation: $animation;
}
.progress.active .progress-bar,
.progress-bar.active {
  @include animation(progress-bar-stripes 2s linear infinite);
}
.progress-striped .progress-bar,
.progress-bar-striped {
  @include gradient-striped;
  background-size: 80px 80px;
}

.arrowButton:hover .shadow {
  opacity: 1;
}
.shadow {
  transition: opacity 0.25s ease-in-out;
}

.ref-shadow-animation {
  animation: slide 4s ease-in-out infinite;
}

@keyframes slide {
  0% {
    transform: translateX(-230px) translateY(0);
  }
  50% {
    transform: translateX(50%) translateY(0);
  }
  100% {
    transform: translateX(-230px) translateY(0);
  }
}

.html {
  scroll-behavior: smooth;
}

.menu-widget {
  animation: fadeIn 0.3s ease-in-out;
}

.project-steps-animation {
  animation: fadeIn 0.7s ease-in-out;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.connect-wallet-bg {
  background-image: url('../public/images/login/headerBanner/connectWallet/connectWalletBg.webp');
  background-position: 95% 30%;
  background-size: 40%;
  background-repeat: no-repeat;
  @media (max-width: 992px) {
    background-position: center 200px;
    background-size: 150%;
  }
}

.register-bg {
  background-image: url('../public/images/login/headerBanner/register/registerBg.webp');
  background-position: 68% 20%;
  background-size: 26%;
  background-repeat: no-repeat;
  @media (max-width: 992px) {
    background-position: center 26rem;
    background-size: 30rem;
  }
}

.preview-account-bg {
  background-image: url('../public/images/login/previewAccount/preview.webp');
  background-position: 90%;
  background-size: contain;
  background-repeat: no-repeat;
  @media (max-width: 992px) {
    background-image: url('../public/images/login/previewAccount/previewMob.webp');
    background-position: top;
    background-size: 35rem;
  }
}

@keyframes animationX {
  0% {
    transform: translateX(-20%);
  }
  100% {
    transform: translateX(180%);
  }
}

@media (max-width: 992px) {
  @keyframes animationX {
    0% {
      transform: translateX(-20%);
    }
    100% {
      transform: translateX(135%);
    }
  }
}

.shadowAnimation-whaleModal {
  animation: animationX 1.5s linear infinite;
}

.dashboard-profile-bg {
  background: radial-gradient(71.28% 35.23% at 50% 0%, rgba(255, 255, 255, 0.05) 0%, rgba(17, 17, 19, 0) 100%),
    rgba(255, 255, 255, 0.04);
  @media (max-width: 992px) {
    background: transparent;
  }
}

.poly-staking-form {
  background: radial-gradient(71.28% 35.23% at 50% 0%, rgba(255, 255, 255, 0.05) 0%, rgba(17, 17, 19, 0) 100%),
    rgba(255, 255, 255, 0.03);
}

.register-plan-animation {
  animation: plans-animation 0.5s ease-out forwards;
}

@keyframes plans-animation {
  0% {
    opacity: 0;
    transform: translateY(5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.landing-dex-banner {
  background-image: url('../public/images/landing/dexBanner/bg.webp');
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 992px) {
    background-image: url('../public/images/landing/dexBanner/mobBg.webp');
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.child::after {
  content: '';
  position: absolute;
  bottom: 0 !important;
  left: 0;
  width: 100%;
  height: 124px; /* Высота тени */
  background: linear-gradient(0deg, #0c0c0e 0%, rgba(12, 12, 14, 0) 100%);
  pointer-events: none;
}

.energy-card-bg {
  background: radial-gradient(71.28% 35.23% at 50% 0%, rgba(255, 255, 255, 0.05) 0%, rgba(17, 17, 19, 0) 100%),
    rgba(255, 255, 255, 0.03);
}

.modal-styles {
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: rgba(255, 255, 255, 0.05);
  background-blend-mode: difference;
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(50px);
}

.profile-bg {
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: radial-gradient(71.28% 35.23% at 50% 0%, rgba(255, 255, 255, 0.05) 0%, rgba(17, 17, 19, 0) 100%),
    rgba(255, 255, 255, 0.04);
  backdrop-filter: blur(15px);
  @media (max-width: 992px) {
    border-radius: 0px;
    border: 0px;
    background: transparent;
    backdrop-filter: blur(0px);
  }
}

.dashboard-progressBar-bg {
  border-radius: 20px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), var(--bg, #0c0c0e);
}

.dashboard-block-styles {
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: rgba(0, 0, 0, 0.3);
  transition: all 200ms ease-out;
  @media (max-width: 992px) {
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(25px);
  }

  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.15);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.02) 0%, rgba(255, 255, 255, 0.02) 100%), rgba(0, 0, 0, 0.3);
  }
}

.stats-blocks-styles {
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: radial-gradient(71.28% 35.23% at 50% 0%, rgba(255, 255, 255, 0.05) 0%, rgba(17, 17, 19, 0) 100%),
    rgba(255, 255, 255, 0.04);
  backdrop-filter: blur(15px);
  @media (max-width: 992px) {
    border-radius: 30px;
    border: none;
    background: transparent;
    backdrop-filter: blur(0px);
  }
}

@keyframes fade-in-dropdown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdown-animation {
  animation: fade-in-dropdown 0.4s ease-out forwards;
}

.partnersPlaceModal {
  background-image: url('../public/images/modals/PartnersPlaceModal/partnersPic.webp');
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
  @media (max-width: 992px) {
    background-image: url('../public/images/modals/PartnersPlaceModal/partnersPicMob.webp');
    background-position: center 70%;
    background-size: 40rem;
  }
}

.socialMiningLaunchBg {
  background-image: url('../public/images/landing/socialMiningLaunch/bg.webp');
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 992px) {
    background-image: url('../public/images/landing/socialMiningLaunch/bgMob.webp');
    background-position: center;
    background-size: cover;
  }
}

.nft-banner-border {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23484849FF' stroke-width='1' stroke-dasharray='5%2c5' stroke-dashoffset='17' stroke-linecap='butt'/%3e%3c/svg%3e");
}

.dashboard-profits-bg {
  background-image: url('../public/images/dashboard/profile/profit/bg.webp');
  background-size: cover;
  @media (max-width: 992px) {
    background-image: none;
  }
}

.loginBannerText {
  background: linear-gradient(273deg, #fff 45.56%, rgba(255, 255, 255, 0.71) 85.65%, rgba(255, 255, 255, 0.3) 109.85%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.loginBannerBg {
  background-image: url('../public/images/landing/loginBanner/bg.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 992px) {
    background-image: url('../public/images/landing/loginBanner/bgMob.webp');
  }
}

.futureBg {
  background-image: url('../public/images/target/Future/bg.webp');
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.target-title-color {
  background: linear-gradient(272deg, #fff 44.6%, #e1e1e1 64.47%, #919191 94.87%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.target-cards-bg {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0.03) 100%), rgba(12, 12, 14, 0.8);
  box-shadow: 0px 18.171px 54.513px 0px #0c0c0e, 0px 0px 35.273px 0px rgba(255, 255, 255, 0.1) inset;
  backdrop-filter: blur(25.19513702392578px);
}

.target-earn-bg {
  background-image: url('../public/images/target/Earn/bg.webp');
  background-position: center -15rem;
  background-size: 160rem;
  background-repeat: no-repeat;
  @media (max-width: 992px) {
    background-position: center 2rem;
    background-size: 65rem;
  }
}

.stagesSocialMining-bg {
  background-image: url('../public/images/target/StagesSocialMining/bg.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 992px) {
    background-position: 0 30rem;
    background-size: contain;
  }
}

.stagesSocialMining-shadow {
  &-purple {
    box-shadow: 0px 24.54px 39.075px 0px rgba(12, 12, 14, 0.9), 0px 24.54px 39.075px 0px rgba(12, 12, 14, 0.9),
      0px 0px 60.303px -32.471px rgba(152, 90, 255, 0.9) inset, 0px 0px 16.235px 0px rgba(255, 255, 255, 0.1) inset;
  }
  &-orange {
    box-shadow: 0px -49.08px 78.15px 0px rgba(12, 12, 14, 0.9), 0px 49.08px 78.15px 0px rgba(12, 12, 14, 0.9),
      0px 0px 120.607px -64.942px rgba(241, 161, 59, 0.9) inset, 0px 0px 32.471px 0px rgba(255, 255, 255, 0.1) inset;
  }
}

.stagesSocialMining-counter-shadow {
  &-purple {
    box-shadow: 0px 0px 31.543px 0px rgba(255, 255, 255, 0.55) inset, 0px 0px 231.936px 0px #985aff,
      0px 0px 231.936px 0px #985aff, 0px 0px 79.489px 0px #985aff, 0px 0px 22.711px 0px #985aff,
      0px 0px 11.356px 0px #985aff;
  }
  &-orange {
    box-shadow: 0px 0px 250px 0px #f1a13b, 0px 0px 250px 0px #f1a13b, 0px 0px 79.489px 0px #f1a13b,
      0px 0px 22.711px 0px #f1a13b, 0px 0px 11.356px 0px #f1a13b;
  }
}

.stagesSocialMining-counter-number {
  background: linear-gradient(324deg, #fff -11.2%, rgba(255, 255, 255, 0.3) 143.82%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.streams-bg {
  background-image: url('../public/images/target/Streams/bg.webp');
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 992px) {
    background-image: none;
  }
}

.hurryUp-bg {
  background-image: url('../public/images/target/HurryUp/bg.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
